const beforeEach = (to, from, next) => {
    if (to.meta.requiresAuth) {
        if (window.sessionStorage.getItem('user')) {
            next()
        } else {
            next('/')
        }
    } else {
        next()
    }
}

export default beforeEach