import {
    eventHub
} from './../../events/eventhub'

export default (axios, store, router) => {
    const client = axios.create({
        baseURL: process.env.VUE_APP_URL_API,
        headers: {
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer ' + store.state.auth.token
        }
    })

    client.interceptors.request.use(config => {
        if (store.getters.isLogged) config.headers['Authorization'] = 'Bearer ' + store.state.auth.token;
        eventHub.$emit('loading-show')
        return config
    }, error => {
        return Promise.reject(error)
    })

    client.interceptors.response.use(response => {
        eventHub.$emit('loading-hide')
        return response
    }, error => {
        eventHub.$emit('loading-hide')
        const {
            response
        } = error
        if ([400, 401].indexOf(response.status) > -1) {
            store.dispatch('logout')
            router.push('/')
        }
        return Promise.reject(error)
    })

    return client
}