import * as service from './../../../services/auth'

export const attempLogin = async ({
    commit
}, payload) => {
    try {
        let resp = await service.login(payload.axios, payload)
        sessionStorage.setItem('user', JSON.stringify({
            ...resp.data
        }))
        sessionStorage.setItem('token', JSON.stringify(resp.data.token))
        commit('AUTH_SUCCESS', {
            ...resp.data
        })
        return resp
    } catch (error) {
        // commit('LOGOUT')
        return error
    }
}

export const logout = async ({
    commit
}) => {
    try {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('token')
        commit('LOGOUT')
    } catch (error) {
        return error
    }
}