const routes = [{
        path: '/',
        redirect: '/auth'
    }, {
        name: 'auth',
        path: '/auth',
        component: () => import('../pages/Login.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('../pages/Dashboard/Dashboard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'search',
        path: '/search',
        component: () => import('../pages/Consultas/ConsultaTab.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        name: 'CanalTabs',
        path: '/canal',
        component: () => import('./../pages/Cadastros/Canal/CanalTabs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'LojaTabs',
        path: '/loja/:id?',
        component: () => import('./../pages/Cadastros/Loja/LojaTabs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'UsuarioTabs',
        path: '/usuario/:unidade',
        component: () => import('./../pages/Cadastros/Usuario/UsuarioTabs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'canalVendaCad',
        path: '/canal/cad/:id?',
        component: () => import('./../pages/Cadastros/Canal/Canal-Cad.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'lojaCad',
        path: '/loja/cad/:canal/:id?',
        component: () => import('./../pages/Cadastros/Loja/Loja-Cad.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'usuarioCad',
        path: '/usuario/cad/:unidade/:id?',
        component: () => import('./../pages/Cadastros/Usuario/UsuarioCad.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'vendas',
        path: '/vendas',
        component: () => import('./../pages/Vendas/ContratosTabs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'vendasDetalhes',
        path: '/vendas/detalhes/:venda?',
        component: () => import('./../pages/Vendas/ContratoDetalhesTabs.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'alterarSenha',
        path: '/usuario/alterar/senha',
        component: () => import('./../pages/Cadastros/Usuario/Usuario-Senha.vue'),
        meta: {
            requiresAuth: true
        }
    },
]

export default routes