import Vue from 'vue'

import Vuetify from 'vuetify'
import {
  Ripple
} from 'vuetify/lib/directives'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify, {
  lang: {
    locales: {
      pt
    },
    current: 'pt'
  },
  directives: {
    Ripple
  },
  theme: {
    primary: process.env.VUE_APP_COLOR,
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },

  customProperties: true,
  iconfont: 'fa4',
})

import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(VuetifyToast, {
  x: 'right', // default
  y: 'top', // default
  color: 'info', // default
  icon: 'info',
  timeout: 5000, // default
  dismissable: true, // default
  autoHeight: false, // default
  multiLine: false, // default
  vertical: false, // default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast' // default
})