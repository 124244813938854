import Vue from 'vue'
import App from './App.vue'

import router from './routes'

import store from './store'

import './plugins/vuetify'

import './plugins/sweetalert2'

import './plugins/loading'

import './plugins/currency'

import './plugins/filters/currency'

import axios from 'axios'
import axiosPlugin from './plugins/axios'

Vue.use({
  install(Vue) {
    Vue.prototype.$axios = axiosPlugin(axios, store, router)
  }
})

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue() // Global event bus

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')