<template>
  <v-app id="app">
    <Sidebar v-if="currentUser" />
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
const Sidebar = () => import('./components/Sidebar')
import { eventHub } from './events/eventhub.js'

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data () {
    return {
      loader: null,
    }
  },
  created () {
    eventHub.$on('loading-show', this.loadingShow)
    eventHub.$on('loading-hide', this.loadingHide)
  },
  beforeDestroy () {
    eventHub.$off('loading-show', this.loadingShow)
    eventHub.$off('loading-hide', this.loadingHide)
  },
  computed: {
    ...mapGetters(['isLogged', 'currentUser'])
  },
  methods: {
    loadingShow () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
    },
    loadingHide () {
      if (this.loader) {
        this.loader.hide()
        this.loader = null
      }
    },
  },

}
</script>

<style>
</style>
